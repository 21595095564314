import React, { useState, useEffect } from 'react';
import Navhead from './Navhead';
import {Link} from 'react-router-dom';

export default function Checkout(props) {
  let cartItems=props.cartItems;
  const onAdd = props.onAdd;
  const onRemove = props.onRemove;

  const itemsPrice = cartItems.reduce((a, c) => a + c.qty * c.price, 0);
  const taxPrice = itemsPrice * 0.05;
  const shippingPrice = itemsPrice > 2000 ? 0 : 30;
  const totalPrice = itemsPrice + taxPrice + shippingPrice;

  const email=JSON.parse(localStorage.getItem('emaillocal'));
  const [data,setData]=useState([]);
    
function protopay(){
  alert('Currently we do not accept direct order from this location, kindly use other delivery options')
}


  useEffect(async ()=>{
    let result=await fetch('https://foodyinn.in/api/fetchdeliveryaddress.php?prod='+email);
    result = await result.json();
    setData(result);
    console.warn("data",data);
},[])




  return (
      <div>
    <Navhead cartItemnav={cartItems.length} />
    
      <h2>Checkout</h2>

      <div class="container">
        <div class="row">
          <div class="col-sm-8" >
          <ul class="list-group">
            <li class="list-group-item bg-primary text-white" style={{textAlign:"left"}} >Your Delivery Address</li>

            {

data.map((item)=>
<li style={{textAlign:"left"}} class="list-group-item">
<input type="radio" class="form-check-input" id="radio2" name="deladd" />
  <b>{item.username}</b> {item.email} {item.address} Mobile: {item.mobile}</li>

)

      }    

<Link to="/newdeliveryaddress" style={{textDecoration:'none'}}>
  <li class="list-group-item bg-dark text-white" style={{textAlign:"left"}} >+ Add New Address</li>
</Link>


          </ul>
          <ul class="list-group" style={{textAlign:"left"}} >
          <li class="list-group-item bg-primary text-white">Payment Methods</li>
                                      <li class="list-group-item">
                                            <div class="form-check">
                                              <label class="form-check-label" for="radio2">
                                                <input type="radio" class="form-check-input" id="payment_type" name="payment_type" value="cod" /><b>COD</b>
                                              </label>
                                            </div>   
                                      </li> 		
                                      <li class="list-group-item">
                                            <div class="form-check">
                                              <label class="form-check-label" for="radio2">
                                                <input type="radio" class="form-check-input" id="payment_type" name="payment_type" value="razorpay" /><b>Credit Card / Debit Card / Net Banking / Wallets </b>
                                              </label>
                                            </div>   
                                      </li> 
                                      <li class="list-group-item">
                                            <div class="form-check">
                                              <label class="form-check-label" for="radio2">
                                                <input type="radio" class="form-check-input" id="payment_type" name="payment_type" value="paytm" /><b>Paytm </b>
                                              </label>
                                            </div>   
                                      </li>            
          </ul>
          </div>
          <div class="col-sm-4">

          <div class="list-group">
          <li class="list-group-item bg-primary text-white">Item Details</li>
{cartItems.map((item) => (
<li  class="list-group-item list-group-item-action">
    <div class="justify-content-left" style={{textAlign:'left'}}>
    <img className="small" src={"https://foodyinn.in/products/" + item.thumbnail} alt={item.name} style={{height:'30px', width:'30px', borderRadius:'5px'}} />
            &nbsp; {item.name}        

    </div>                  
    <div class="justify-content-left" style={{textAlign:'right'}}>

    Qty: {item.qty} x Rs.{item.price} = Rs. {item.qty * item.price}    
    </div>
</li>



))}



{cartItems.length === 0? "":

<table class="table table-borderless">
<tbody>
<tr style={{borderTopStyle:"double"}}>
<td style={{textAlign:'left'}}><b>Tax:</b></td>
<td style={{textAlign:'right'}}>{taxPrice}</td>
</tr>
<tr >
<td style={{textAlign:'left'}}><b>Shipping Price:</b></td>
<td style={{textAlign:'right'}}>{shippingPrice}</td>
</tr>
<tr style={{borderBottomStyle:"double"}}>
<td style={{textAlign:'left'}}><b>Total Price:</b></td>
<td style={{textAlign:'right'}}>{totalPrice}</td>
</tr>    
<tr>
<td colSpan="2"><b><button class="btn btn-outline-primary" onClick={protopay}>Proced to Pay</button></b></td>

</tr>      
</tbody>
</table>




}        
</div>


          </div>
          </div>          
      </div>
      <div>
      {cartItems.length === 0 && <div>Cart is empty</div>}


      </div>
    

    </div>
  );
}

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navhead from './Navhead';

function Account(props) {
    let cartItems = props.cartItems;

    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [emailreg, setEmailreg] = useState("");
    const [passwordreg, setPasswordreg] = useState("");
    const [mobile, setMobile] = useState("");
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [logstatus, setLogstatus] = useState("");
    const [Signupstatus, setSignupstatus] = useState("");
    const [Loginstatus, setLoginstatus] = useState("");
    function saveUser() {
        console.warn(fname, lname, emailreg, passwordreg, mobile);

        let data = { fname, lname, emailreg, passwordreg, mobile };

        if (fname === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (lname === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (emailreg === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (passwordreg === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (mobile === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else {


            fetch("https://www.starsplex.com/api/create.php", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)


            }).then((result) => {
                // console.warn("result",result);
                result.json().then((resp) => {
                    console.warn("resp", resp);
                    localStorage.setItem("user-name", JSON.stringify(fname));
                    localStorage.setItem("emaillocal", JSON.stringify(emailreg));
                    localStorage.setItem("user-info", JSON.stringify(resp));

                    // navigate("/navhead");
                    navigate("/dashboard");

                })
            })

        }
    }

    function userLogin() {
        console.warn(email, password);

        let data = { email, password };

        if (email === "") {
            setLoginstatus("Please Enter Valid Email ID & Password");
        }
        else if (password === "") {
            setLoginstatus("Please Enter Valid Email ID & Password");
        }
        else {

            fetch("https://www.starsplex.com/api/login.php", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)


            }).then((result) => {
                // console.warn("result",result);
                result.json().then((resp) => {
                    console.warn("resp", resp);
                    if (resp !== "NO") {
                        localStorage.setItem("user-name", JSON.stringify(resp[1].fname));
                        localStorage.setItem("emaillocal", JSON.stringify(email));
                        localStorage.setItem("user-info", JSON.stringify(resp));

                        // navigate("/navhead");
                        navigate("/dashboard");
                        console.warn("Login", resp);

                    }
                    else {
                        console.warn("Login Failed!");
                        setLogstatus("Failed");
                    }


                })
            })
        }
    }


    return (
        <div>
            <Navhead cartItemnav={cartItems.length} />
            <br />

            <div class="container">
                <h2>My Account</h2>
                <br />
                <nav class="d-flex justify-content-center">
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#signin">Sign In</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#signup">Sign Up</a>
                        </li>
                    </ul>
                </nav>

                <div class="tab-content">
                    <div id="signin" class="container tab-pane active"><br />
                        <h3>Login</h3>
                        <div class="col-sm-4 d-block mx-auto">

                            <p style={{ color: 'red' }}>{Loginstatus}</p>
                            <br />
                            <div class="form-group">
                                <input type="email" class="form-control" value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder="Enter email" name="emailreg" />
                            </div>

                            <div class="form-group">
                                <input type="password" class="form-control" value={password} placeholder="Enter password" onChange={(e) => { setPassword(e.target.value) }} name="passwordreg" />
                            </div>

                            <button type="submit" class="btn btn-primary" onClick={userLogin}> Login </button>
                            {logstatus}
                        </div>



                    </div>
                    <div id="signup" class="container tab-pane fade"><br />
                        <h3>Create New Account</h3>

                        <div class="col-sm-4 d-block mx-auto">

                            <p style={{ color: 'red' }}>{Signupstatus}</p>
                            <br />
                            <div class="form-group">
                                <input type="text" class="form-control" value={fname} onChange={(e) => { setFname(e.target.value) }} placeholder="Enter first name" name="fname" />
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" value={lname} onChange={(e) => { setLname(e.target.value) }} placeholder="Enter last name" name="lname" />
                            </div>

                            <div class="form-group">
                                <input type="email" class="form-control" value={emailreg} onChange={(e) => { setEmailreg(e.target.value) }} placeholder="Enter email" name="emailreg" />
                            </div>

                            <div class="form-group">
                                <input type="password" class="form-control" value={passwordreg} placeholder="Enter password" onChange={(e) => { setPasswordreg(e.target.value) }} name="passwordreg" />
                            </div>

                            <div class="form-group">
                                <input type="text" class="form-control" value={mobile} onChange={(e) => { setMobile(e.target.value) }} placeholder="Enter mobile" name="mobile" />
                            </div>

                            <button type="submit" class="btn btn-primary" onClick={saveUser}> Sign Up Now! </button>

                        </div>



                    </div>
                </div>
            </div>



        </div>
    );
}
export default Account;
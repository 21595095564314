import React from 'react';
import { Link } from 'react-router-dom';
import Navhead from './Navhead';

export default function Basket(props) {
  let cartItems = props.cartItems;
  const onAdd = props.onAdd;
  const onRemove = props.onRemove;

  const itemsPrice = cartItems.reduce((a, c) => a + c.qty * c.price, 0);
  const taxPrice = itemsPrice * 0.05;
  const shippingPrice = itemsPrice > 2000 ? 0 : 30;
  const totalPrice = itemsPrice + taxPrice + shippingPrice;
  return (
    <div>
      <Navhead cartItemnav={cartItems.length} />
      <aside className="container-fluid" style={{ padding: '0px' }}>
        <li class="list-group-item bg-dark text-white"><i>Your Cart</i></li>
        <div>
          {cartItems.length === 0 && <div>Cart is empty</div>}

          <div class="list-group">

            {cartItems.map((item) => (
              <li class="list-group-item list-group-item-action">
                <div class="justify-content-left" style={{ textAlign: 'left' }}>
                  <img className="small" src={"https://foodyinn.in/products/" + item.thumbnail} alt={item.name} style={{ height: '30px', width: '30px', borderRadius: '5px' }} />
                  &nbsp; {item.name}

                  &nbsp;
                </div>
                <div class="justify-content-left" style={{ textAlign: 'right' }}>

                  <button onClick={() => onRemove(item)} class='btn btn-outline-primary btn-sm'>
                    -
                  </button>

                  <button class='btn btn-outline-info btn-sm'>
                    {item.qty}
                  </button>

                  <button onClick={() => onAdd(item)} class='btn btn-outline-danger btn-sm'>
                    +
                  </button>
                  <br />

                  Qty: {item.qty} x Rs.{item.price} = Rs. {item.qty * item.price}
                </div>


              </li>



            ))}



            {cartItems.length === 0 ? "" :

              <table class="table table-borderless">
                <tbody>
                  <tr style={{ borderTopStyle: "double" }}>
                    <td style={{ textAlign: 'left' }}><b>Tax:</b></td>
                    <td style={{ textAlign: 'right' }}>{taxPrice}</td>
                  </tr>
                  <tr >
                    <td style={{ textAlign: 'left' }}><b>Shipping Price:</b></td>
                    <td style={{ textAlign: 'right' }}>{shippingPrice}</td>
                  </tr>
                  <tr style={{ borderBottomStyle: "double" }}>
                    <td style={{ textAlign: 'left' }}><b>Total Price:</b></td>
                    <td style={{ textAlign: 'right' }}>{totalPrice}</td>
                  </tr>
                  <tr>
                    <td colSpan="2"><Link to="/checkout"><button class="btn btn-outline-primary">Proced to Checkout</button></Link></td>

                  </tr>
                </tbody>
              </table>




            }
          </div>
        </div>
      </aside>

    </div>
  );
}

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navhead from './Navhead';
import { Helmet } from 'react-helmet';
import Footer from './Footer';

function Support(props) {
    let cartItems = props.cartItems;

    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [emailreg, setEmailreg] = useState("");
    const [passwordreg, setPasswordreg] = useState("");
    const [mobile, setMobile] = useState("");
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [logstatus, setLogstatus] = useState("");
    const [Signupstatus, setSignupstatus] = useState("");
    const [Loginstatus, setLoginstatus] = useState("");
    const [sent, setSent] = useState(false);

    function saveUser() {
        console.warn(fname, lname, emailreg, passwordreg, mobile);

        let data = { fname, lname, emailreg, passwordreg, mobile };

        if (fname === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (lname === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (emailreg === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (passwordreg === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (mobile === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else {


            fetch("https://www.starsplex.com/api/contactus.php", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)


            }).then((result) => {
                // console.warn("result",result);
                result.json().then((resp) => {
                    console.warn("resp", resp);
                    // navigate("/navhead");
                    setSent(true);

                })
            })

        }
    }

/*     function userLogin() {
        console.warn(email, password);

        let data = { email, password };

        if (email === "") {
            setLoginstatus("Please Enter Valid Email ID & Password");
        }
        else if (password === "") {
            setLoginstatus("Please Enter Valid Email ID & Password");
        }
        else {

            fetch("https://www.starsplex.com/api/memberlogin.php", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)


            }).then((result) => {
                // console.warn("result",result);
                result.json().then((resp) => {
                    console.warn("resp", resp);
                    if (resp !== "NO") {
                        localStorage.setItem("user-name", JSON.stringify(resp));
                        localStorage.setItem("emaillocal", JSON.stringify(email));
                        localStorage.setItem("user-info", JSON.stringify(resp));

                        // navigate("/navhead");
                        navigate("/membersdashboard");
                        console.warn("Login", resp);

                    }
                    else {
                        console.warn("Login Failed!");
                        setLogstatus("Failed");
                    }


                })
            })
        }
    }
 */

    return (
        <div>
            <Navhead cartItemnav={cartItems.length} />
            <br />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Support Center | Starsplex - Watch Movies,Videos Musics Online</title>
                <meta property="title" content="Support Center | Starsplex - Watch Movies,Videos Musics Online" />
                <meta property="description" content="Support Center | Starsplex - Watch Movies,Videos Musics Online" />
            </Helmet>
            <div class="container">
                <h2>Support Center</h2>
                <br />

                <div class="container"><br />

                    <div class="col-sm-8 d-block mx-auto">

                        <p style={{ color: 'red' }}>{Signupstatus}</p>
                        <br />
                        <div class="form-group">
                            <input type="text" class="form-control" value={fname} onChange={(e) => { setFname(e.target.value) }} placeholder="Enter Name" name="fname" />
                        </div>
                        <div class="form-group">
                            <input type="email" class="form-control" value={emailreg} onChange={(e) => { setEmailreg(e.target.value) }} placeholder="Enter Email" name="emailreg" />
                        </div>

                        <div class="form-group">
                            <input type="text" class="form-control" value={mobile} onChange={(e) => { setMobile(e.target.value) }} placeholder="Enter mobile" name="mobile" />
                        </div>


                        <div class="form-group">
                            <input type="text" class="form-control" value={passwordreg} placeholder="Enter Subject" onChange={(e) => { setPasswordreg(e.target.value) }} name="passwordreg" />
                        </div>

                        <div class="form-group">
                            <textarea class="form-control" rows="5" id="comment" value={lname} onChange={(e) => { setLname(e.target.value) }} placeholder="Enter Description" name="lname"></textarea>

                        </div>

                        <button type="submit" class="btn btn-primary" onClick={saveUser}> Send Now! </button>

                    </div>
                    <div style={{marginTop:"25px"}}>
                        <h6><b>Note:</b><br/> For any queries please feel free to contact us at <b>starsplexofficial@gmail.com</b></h6>
                    </div>

                    <div style={{ height: '100px', marginTop: '50px' }}>
                        {
                            sent ?
                                <div class="alert alert-success alert-dismissible">
                                    <button type="button" class="close" data-dismiss="alert">&times;</button>
                                    <strong>Sent!</strong>  Ticket raised successfully!
                                </div>
                                : ""
                        }

                    </div>

                </div>

            </div>

            <Footer />

        </div>
    );
}
export default Support;
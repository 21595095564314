import React from 'react';
import {Link} from "react-router-dom";

export function Filterside(){
    return(
      <div>
<ul class="list-group" id="mobile">
    <li class="list-group-item bg-dark text-white">Starsplex Menu</li>
    <Link class="list-group-item list-group-item-action" to="/indianfoods">Indian</Link>      
    <Link class="list-group-item list-group-item-action" to="/chinesefoods">Chinese</Link>      
    <Link class="list-group-item list-group-item-action" to="/tandoorfoods">Tandoor</Link>      
    <Link class="list-group-item list-group-item-action" to="/vegfoods">Veg</Link>
</ul>          
      </div>  
    )
}
import React, { useState, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import { Dashsidelist } from './Dashsidelist';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';

import Navhead from './Navhead';

export default function Orders(props) {

  let cartItems = props.cartItems;

  const email = JSON.parse(localStorage.getItem('emaillocal'));
  const names = JSON.parse(localStorage.getItem('user-name'));
  const navigatelog = useNavigate();

  const [userdet, setUserdet] = useState([]);

  useEffect(async () => {

    let result = await fetch("https://foodyinn.in/api/fetchlogdetails.php?email=" + email);
    result = await result.json();
    setUserdet(result);
    console.warn("data", userdet);

  }, [])




  return (
    <div>
      <Navhead cartItemnav={cartItems.length} />


      <div class="container product-page" id="top">
        <div class="row">
          <div class="col-sm-12" align={'left'}>
            <Link to="/" style={{ color: '#000000', fontSize: '12px' }}>Home &gt; </Link>
            <Link to="#" style={{ color: '#000000', fontSize: '12px' }}>My Account  </Link>

          </div>
        </div>

      </div>


      <div class="container-fluid product">
        <div class="container">
          <div class="row">

            <div class="col-sm-3" id="mobile">
              <Dashsidelist />
            </div>
            <div class="col-sm-9">
              <div class="row">
                <div class="col-sm-12" >
                  <p align="left">

                    Hello,{names} <br />
                    {email ? "" : navigatelog("/account")}

                    Currently you do not has any order.
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">



                </div>
              </div>







            </div>
            <div class="col-sm-3" id="mobileshow">
              <Dashsidelist />
            </div>

          </div>
          <div class="col-sm-4">

          </div>




        </div>
      </div>

      <Footer />
    </div>
  );

}
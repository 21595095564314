import React,{useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Navhead from './Navhead';

function Logout()
{
    const navigateuser=useNavigate();
    useEffect(() => {

        localStorage.clear();
        localStorage.removeItem("user-name");
        localStorage.removeItem("emaillocal");
        // navigateuser("/navhead");
        navigateuser("/account");
        console.warn("Logout");
    
        
      });

    return(
        <div>
            <Navhead />
        </div>
    );
}
export default Logout;
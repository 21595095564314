import React, { useState, useEffect, useMemo } from 'react';

import { useParams } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import './Proddetails.css';
import Footer from './Footer';
import Navhead from './Navhead';
import { useSearchParams } from 'react-router-dom';
import "./watch.css"

function Watch(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const itemid = searchParams.get('title');

    console.warn("propsdet", props);
    let cartItems = props.cartItems;
    let prodid = useParams();


    const [data, setData] = useState([]);
    const [videodata, setVideodata] = useState([]);
    const [rating, setRating] = useState([]);
    const [ratingavg, setRatingavg] = useState([]);
    const [ratingreview, setRatingreview] = useState([]);
    const { product, onAdd } = props;
    const [similar, setSimilar] = useState([]);
    const navigate = useNavigate();
    const [revbtn, setRevbtn] = useState("Save Review");
    const [loadingstate, setLoadingstate] = useState(true);

    const emailcheck = JSON.parse(localStorage.getItem('emaillocal'));

    useMemo(() => {
        Singleproduct();
    }, [])


    async function Singleproduct() {
        await fetch("https://www.starsplex.com/api/fetchvideodetails.php", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(itemid)
        }).then((result) => {
            result.json().then((resp) => {
                console.log("videodata", videodata)
                setVideodata(resp[0])
                setLoadingstate(false);

            })
        })
    }
    function Similaritems() {
        Singleproduct();
    }


    return (
        <div>
            <Navhead />
            <Helmet>
                <meta charSet="utf-8" />
                <title>{!loadingstate ? videodata.videoname + " | Starsplex - Watch Movies,Videos Musics Online" : "Starsplex - Watch Movies,Videos Musics Online"}</title>
                <meta name={videodata.videoname + "Starsplex - Watch Movies,Videos Musics Online"} content={videodata.videoname + "Starsplex - Watch Movies,Videos Musics Online"} data-react-helmet="true" />
                <meta property="og:title" name='title' content={videodata.videoname + "Starsplex - Watch Movies,Videos Musics Online"} data-react-helmet="true" />
                <meta property="description" name='description' content={videodata.videoname + "Starsplex - Watch Movies,Videos Musics Online"} data-react-helmet="true" />
                <meta property="keywords" name='keywords' content={videodata.videoname + "Starsplex - Watch Movies,Videos Musics Online"} data-react-helmet="true" />
                <meta name="og_image" property="og:image" content={"https://www.starsplex.com/uploads/img/" + videodata.name} data-react-helmet="true" />
                <noscript>{videodata.videoname + " | Starsplex - Watch Movies,Videos Musics Online"}</noscript>
            </Helmet>

            {
                !loadingstate ?
                    <div>
                        <div class="details_video iframemobdes" id="videoplay" style={{ marginTop: '-15px' }}>
                            <div class="embed-responsive embed-responsive-16by9" align="center">
                                <iframe src={videodata.youtube + `?rel=0&modestbranding=1`} frameborder="0" id="videoplay"
                                    allow="autoplay; fullscreen" allowfullscreen style={{ width: '100%', backgroundColor: 'black' }} class="iframemobdes">
                                </iframe>
                            </div>
                        </div>
                        <h5 class="mx-3 mt-3 text-left"> <b>{videodata.videoname}</b></h5>
                        <h6 class="mx-3 mt-3 text-left"> <span class="badge badge-secondary px-4 py-1 text-left">{videodata.certification}</span></h6>
                        <h6 class="mx-3 mt-3 text-left"> <b>Release Date</b> {videodata.date}</h6>
                        {videodata.cast !== "" ?
                            <h6 class="mx-3 mt-3 text-left"> <b>Cast</b> {videodata.cast}</h6>
                            : ""}
                        {videodata.duration !== "" ?
                            <h6 class="mx-3 mt-3 text-left"> <b>Duration</b> {videodata.duration}</h6>
                            : ""
                        }

                        <h6 class="mx-3 mt-3 text-left"> <b>Director</b> {videodata.director}</h6>
                        <h6 class="mx-3 mt-3 text-left"> <b>Languages</b> {videodata.language}</h6>
                        {videodata.description !== "" ?
                            <h6 class="mx-3 mt-3 text-left"> <b>Story</b> {videodata.description}</h6>
                            : ""
                        }
                        <nav class="navbar navbar-expand-sm bg-dark navbar-dark mt-5">
                            <div class="container d-flex justify-content-center" >
                                <a class="navbar-brand" >©  {videodata.copyright}</a>
                            </div>
                        </nav>
                    </div>
                    : <div class="iframemobdes" style={{ backgroundColor: 'black', marginTop: '-15px' }}><div class="align-middle"><div class="spinner-border text-light margintop"></div> </div></div>}
            <Footer />
        </div>
    );
}
export default Watch;
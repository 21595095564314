import React,{useState,useEffect} from 'react';
import {NavLink,Link} from "react-router-dom";
import { Dashsidelist } from './Dashsidelist';
import { Filterside } from './Filterside';
import Footer from './Footer';
import Navhead from './Navhead';
import {useNavigate} from 'react-router-dom';

export default function Deliveryaddress(props){
  
  let cartItems=props.cartItems;

  const email=JSON.parse(localStorage.getItem('emaillocal'));
  const names=JSON.parse(localStorage.getItem('user-name'));
  const navigatelog=useNavigate();

    const [userdet,setUserdet]=useState([]);


    const [data,setData]=useState([]);
  
    
    useEffect(async ()=>{      

      let result=await fetch("https://foodyinn.in/api/fetchlogdetails.php?email="+email);
      result = await result.json();
      setUserdet(result);
      console.warn("data",userdet);
      
  },[])

  useEffect(async ()=>{
    let result=await fetch('https://foodyinn.in/api/fetchdeliveryaddress.php?prod='+email);
    result = await result.json();
    setData(result);
    console.warn("data",data);
},[])

 

    return(
      <div>
          <Navhead cartItemnav={cartItems.length} />
          

<div class="container product-page" id="top">
<div class="row">
                <div class="col-sm-12" align={'left'}>
                <Link to="/" style={{color:'#000000', fontSize:'12px'}}>Home &gt; </Link>          
                <Link to="#" style={{color:'#000000', fontSize:'12px'}}>My Account  </Link>          

                </div>   
           </div>

    </div>
 
 
 <div class="container-fluid product">
   <div class="container">
    <div class="row">

      <div class="col-sm-3" id="mobile">
        <Dashsidelist />
      </div>
      <div class="col-sm-9">
          <div class="row">
              <div class="col-sm-12" >
                 <p align="left">    

                 Hello,{names} <br/>
                 {email? ""   :navigatelog("/account")}
                  
From your account dashboard you can view your recent orders and manage account details and Delivery Addresses.
                 </p>
              </div>       
          </div>
          
          <div class="row">
        <div class="col-sm-12">
        

        <ul class="list-group">
            <li class="list-group-item bg-primary text-white" style={{textAlign:"left"}} >Your Delivery Address</li>

            {

data.map((item)=>
<li style={{textAlign:"left"}} class="list-group-item">
<input type="radio" class="form-check-input" id="radio2" name="deladd" />
  <b>{item.username}</b> {item.email} {item.address} Mobile: {item.mobile}</li>

)

      }    

<Link to="/newdeliveryaddress" style={{textDecoration:'none'}}>
  <li class="list-group-item bg-dark text-white" style={{textAlign:"left"}} >+ Add New Address</li>
</Link>
          </ul>


        </div>
</div>



 
 
 
            
         </div>
      <div class="col-sm-3" id="mobileshow">
        <Dashsidelist />
      </div>

      </div>
      <div class="col-sm-4">
 
      </div>
 
 
 
 
    </div>
   </div>


<Footer />
      </div>  
    )
}
import React, { useEffect, useState } from 'react';
import Navhead from './Navhead';
import { NavLink } from 'react-router-dom';

function SearchProduct(props) {
    let cartItems = props.cartItems;
    const [data, setData] = useState([]);
    const [datatrend, dataSetTrend] = useState([]);

    useEffect(() => {
        fetch("https://starsplex.com/api/trendingsearches.php").then((resulttrend) => {
            resulttrend.json().then((resptrend) => {

                dataSetTrend(resptrend);
                console.warn("RESPindian", resptrend);
            })
        });
    }, [])



    async function search(key) {

        if (key.length > 1) {
            let result = await fetch('https://www.starsplex.com/api/searchall.php?prod=' + key);
            result = await result.json();
            setData(result);
            console.warn(key);
            console.warn(result);

        }
        else if (key.length < 1) {
            setData("");
        }
        else {
            setData("");
        }


    }


    return (
        <div>
            <Navhead cartItemnav={cartItems.length} />
            <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <div class="form-group">
                    <input style={{ borderColor: 'skyblue', fontStyle: 'italic', fontWeight: '600' }} type="text" class="form-control" onChange={(e) => { search(e.target.value) }} placeholder="Search ..." name="name" />
                </div>
                {
                    data.length > 0 ?
                        <div class="list-group">




                            {
                                data.map((item) =>
                                    <NavLink align={'left'} to={"/watch?title=" + item.id + "&n=" + item.videoname} style={{ textDecoration: 'none', width: '100%' }} class="list-group-item list-group-item-action list-group-item-dark">


                                        <img align={'left'} src={"https://www.starsplex.com/uploads/img/" + item.name} style={{ height: "50px", width: "50px", borderRadius: "15px", padding: '10px' }} alt={item.thumbnail} />
                                        <h6 align={'left'} style={{ padding: '10px' }}>{item.videoname}</h6>



                                    </NavLink>

                                )
                            }

                        </div>
                        : null
                }



                <h4 style={{ textAlign: 'left' }}><i>&nbsp;Trending Searches</i></h4>

                <div class="list-group">

                    {
                        datatrend.map((itemtrend) =>
                            <NavLink align={'left'} to={"/watch?title=" + itemtrend.id + "&n=" + itemtrend.videoname} style={{ textDecoration: 'none', width: '100%' }} class="list-group-item list-group-item-action list-group-item-dark">


                                <img align={'left'} src={"https://www.starsplex.com/uploads/img/" + itemtrend.name} style={{ height: "50px", width: "50px", borderRadius: "15px", padding: '10px' }} alt={itemtrend.thumbnail} />
                                <h6 align={'left'} style={{ padding: '10px' }}>{itemtrend.videoname}</h6>



                            </NavLink>

                        )
                    }

                </div>
            </div>
        </div>
    );
}
export default SearchProduct;
import React, { useEffect, useState } from 'react';
import Navhead from './Navhead';
import { Link, NavLink } from 'react-router-dom';
import './Home.css';
import { Helmet } from 'react-helmet';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import Footer from './Footer';


function Home(props) {
    let cartItems = props.cartItems;
    const [data, setData] = useState([]);
    const [datavideos, setDatavideos] = useState([]);
    const [datafilms, setDatafilms] = useState([]);
    const [datamusic, setDatamusic] = useState([]);
    const [dataclassic, setDataclassic] = useState([]);
    const [dataconcerts, setDataConcerts] = useState([]);
    const [veg, setVeg] = useState([]);

    useEffect(() => {

        fetch("https://www.starsplex.com/api/latestshows.php").then((result) => {
            result.json().then((resp) => {

                setData(resp);
                console.warn("RESP", resp);
            })
        });

        fetch("https://www.starsplex.com/api/fetchvideos.php").then((resultvideos) => {
            resultvideos.json().then((respvideos) => {
                setDatavideos(respvideos);
            })
        });
        fetch("https://www.starsplex.com/api/fetchmovies.php").then((resultfilms) => {
            resultfilms.json().then((respfilms) => {

                setDatafilms(respfilms);

            })
        });
        fetch("https://www.starsplex.com/api/fetchmusics.php").then((resultmusic) => {
            resultmusic.json().then((respmusic) => {

                setDatamusic(respmusic);
            })
        });
        fetch("https://www.starsplex.com/api/fetchclassic.php").then((resultclassic) => {
            resultclassic.json().then((respclassic) => {

                setDataclassic(respclassic);
            })
        });

        fetch("https://www.starsplex.com/api/fetchconcerts.php").then((resultconcerts) => {
            resultconcerts.json().then((respconcerts) => {

                setDataConcerts(respconcerts);
            })
        });


        /*         fetch("https://foodyinn.in/api/fetchproductsveg.php").then((resultveg) => {
                    resultveg.json().then((respveg) => {
        
                        setVeg(respveg);
                        console.warn("RESPveg", respveg);
                    })
                });
         */

    }, [])

    const options = {
        stagePadding: 39,
        nav: false,
        dots: false,
        margin: 10,
        loop: true,
        autoplay: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    };

    const optionswn = {
        loop: false,
        nav: false,

        dots: false,
        margin: 60,
        arrow: false,
        responsiveClass: true,
        responsive: {
            0: {
                items: 2
            },
            600: {
                items: 3
            },
            1000: {
                items: 5
            }
        }
    };




    return (
        <div>
            <Navhead cartItemnav={cartItems.length} />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Starsplex - Watch Movies,Videos Musics Online</title>
                <meta property="title" content="Starsplex - Watch Movies,Videos Musics Online" />
                <meta property="description" content="Starsplex - Watch Movies,Videos Musics Online" />
                <meta property="og:image" content="%PUBLIC_URL%/assets/icon.png" />
                <noscript>Starsplex - Watch Movies,Videos Musics Online.</noscript>
            </Helmet>

            <OwlCarousel options={options} style={{ marginTop: '1px' }} >
                <div><img id="slidemain" src={"https://starsplex.com/uploads/img/darjararale.png"} alt="No" style={{ borderRadius: '15px' }} /></div>
                <div><img id="slidemain" src={"https://starsplex.com/uploads/img/m2.jpg"} alt="No" style={{ borderRadius: '15px' }} /></div>
                <div><img id="slidemain" src={"https://starsplex.com/uploads/img/kapurush.jpg"} alt="No" style={{ borderRadius: '15px' }} /></div>
            </OwlCarousel>


            {
                data.length !== 0 ?
                    <div class="container" style={{maxWidth:"1366px"}}>
                        <br />
                        <br />
                        <div class="row">
                            <div class="col-sm-12">
                                <h5 align={"left"} style={{ color: "white" }}><i>Whats New!</i></h5>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-sm-12">
                                <OwlCarousel options={optionswn}  >
                                    {
                                        data.map((item) =>
                                            <NavLink to={"/watch?title=" + item.id + "&n=" + item.videoname} style={{ textDecoration: 'none' }}>

                                                <div class="item" style={{ width: '205px', marginLeft: '-200px' }}>
                                                    <img src={"https://www.starsplex.com/uploads/img/" + item.name} style={{ height: "150px", width: "200px", marginRight: '5px', paddingRight: '0px', borderRadius: "10px" }} alt={item.name} />
                                                    <div style={{ backgroundColor: 'black', opacity: '.7', marginTop: '-25px', marginRight: '5px', paddingRight: '5px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', color: '#FFF' }} >{item.videoname.substring(0, 16) + "..."}</div>
                                                </div>
                                            </NavLink>

                                        )
                                    }

                                </OwlCarousel>
                            </div>
                        </div>

                        <br />
                        <div class="row">
                            <div class="col-sm-12">
                                <h5 align={"left"} style={{ color: "white" }}><i>Videos</i></h5>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <OwlCarousel options={optionswn}  >
                                    {
                                        datavideos.map((item) =>
                                            <NavLink to={"/watch?title=" + item.id + "&n=" + item.videoname} style={{ textDecoration: 'none' }}>

                                                <div class="item" style={{ width: '205px', marginLeft: '-200px' }}>
                                                    <img src={"https://www.starsplex.com/uploads/img/" + item.name} style={{ height: "150px", width: "200px", marginRight: '5px', paddingRight: '0px', borderRadius: "10px" }} alt={item.name} />
                                                    <div style={{ backgroundColor: 'black', opacity: '.7', marginTop: '-25px', marginRight: '5px', paddingRight: '5px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', color: '#FFF' }} >{item.videoname.substring(0, 16) + "..."}</div>
                                                </div>
                                            </NavLink>

                                        )
                                    }

                                </OwlCarousel>
                            </div>
                        </div>

                        <br />
                        {/*               <div class="jumbotron jumbotron-fluid bg-warning" style={{ borderRadius: '25px' }}>
                    <div class="container">
                        <h1>Exclusive Offers</h1>
                        <p>Visit our outlet to get exclusive discounts on all food items</p>
                    </div>
                </div> */}
                        <br />

                        <div class="row">
                            <div class="col-sm-12">
                                <h5 align={"left"} style={{ color: "white" }}><i>Films</i></h5>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <OwlCarousel options={optionswn}  >
                                    {
                                        datafilms.map((item) =>
                                            <NavLink to={"/watch?title=" + item.id + "&n=" + item.videoname} style={{ textDecoration: 'none' }}>

                                                <div class="item" style={{ width: '205px', marginLeft: '-200px' }}>
                                                    <img src={"https://www.starsplex.com/uploads/img/" + item.name} style={{ height: "150px", width: "200px", marginRight: '5px', paddingRight: '0px', borderRadius: "10px" }} alt={item.name} />
                                                    <div style={{ backgroundColor: 'black', opacity: '.7', marginTop: '-25px', marginRight: '5px', paddingRight: '5px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', color: '#FFF' }} >{item.videoname.substring(0, 16) + "..."}</div>
                                                </div>
                                            </NavLink>

                                        )
                                    }

                                </OwlCarousel>
                            </div>
                        </div>

                        <br />
                        <div class="row">
                            <div class="col-sm-12">
                                <h5 align={"left"} style={{ color: "white" }}><i>Musics</i></h5>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <OwlCarousel options={optionswn}  >
                                    {
                                        datamusic.map((item) =>
                                            <NavLink to={"/watch?title=" + item.id + "&n=" + item.videoname} style={{ textDecoration: 'none' }}>

                                                <div class="item" style={{ width: '205px', marginLeft: '-200px' }}>
                                                    <img src={"https://www.starsplex.com/uploads/img/" + item.name} style={{ height: "150px", width: "200px", marginRight: '5px', paddingRight: '0px', borderRadius: "10px" }} alt={item.name} />
                                                    <div style={{ backgroundColor: 'black', opacity: '.7', marginTop: '-25px', marginRight: '5px', paddingRight: '5px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', color: '#FFF' }} >{item.videoname.substring(0, 16) + "..."}</div>
                                                </div>
                                            </NavLink>

                                        )
                                    }

                                </OwlCarousel>
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-sm-12">
                                <h5 align={"left"} style={{ color: "white" }}><i>Classics</i></h5>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <OwlCarousel options={optionswn}  >
                                    {
                                        dataclassic.map((item) =>
                                            <NavLink to={"/watch?title=" + item.id + "&n=" + item.videoname} style={{ textDecoration: 'none' }}>

                                                <div class="item" style={{ width: '205px', marginLeft: '-200px' }}>
                                                    <img src={"https://www.starsplex.com/uploads/img/" + item.name} style={{ height: "150px", width: "200px", marginRight: '5px', paddingRight: '0px', borderRadius: "10px" }} alt={item.name} />
                                                    <div style={{ backgroundColor: 'black', opacity: '.7', marginTop: '-25px', marginRight: '5px', paddingRight: '5px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', color: '#FFF' }} >{item.videoname.substring(0, 16) + "..."}</div>
                                                </div>
                                            </NavLink>

                                        )
                                    }

                                </OwlCarousel>
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-sm-12">
                                <h5 align={"left"} style={{ color: "white" }}><i>Concerts</i></h5>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <OwlCarousel options={optionswn}  >
                                    {
                                        dataconcerts.map((item) =>
                                            <NavLink to={"/watch?title=" + item.id + "&n=" + item.videoname} style={{ textDecoration: 'none' }}>

                                                <div class="item" style={{ width: '205px', marginLeft: '-200px' }}>
                                                    <img src={"https://www.starsplex.com/uploads/img/" + item.name} style={{ height: "150px", width: "200px", marginRight: '5px', paddingRight: '0px', borderRadius: "10px" }} alt={item.name} />
                                                    <div style={{ backgroundColor: 'black', opacity: '.7', marginTop: '-25px', marginRight: '5px', paddingRight: '5px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', color: '#FFF' }} >{item.videoname.substring(0, 16) + "..."}</div>
                                                </div>
                                            </NavLink>

                                        )
                                    }

                                </OwlCarousel>
                            </div>
                        </div>

                        <br /><br /><br /><br />
                        {/*                 <div class="row">
                    <div class="col-sm-12">
                        <h3 align={"left"}><i>Veg Items</i></h3>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <OwlCarousel options={optionswn}  >
                            {
                                veg.map((item) =>
                                    <NavLink to={"/prodetails?item=" + item.id + "&title=" + item.name} style={{ textDecoration: 'none' }}>

                                        <div class="item" style={{ width: '205px', marginLeft: '-200px' }}>
                                            <img src={"https://foodyinn.in/products/" + item.thumbnail} style={{ height: "150px", width: "200px", marginRight: '5px', paddingRight: '0px', borderRadius: "10px" }} alt={item.thumbnail} />
                                            <div style={{ backgroundColor: 'black', opacity: '.7', marginTop: '-25px', marginRight: '5px', paddingRight: '5px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', color: '#FFF' }} >{item.name}</div>
                                        </div>
                                    </NavLink>

                                )
                            }

                        </OwlCarousel>
                    </div>
                </div> */}



                    </div>
                    : ""
            }
            <Footer />
        </div>
    );
}
export default Home;
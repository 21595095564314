import React, { useState, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import { Dashsidelist } from './Dashsidelist';
import { Filterside } from './Filterside';
import Footer from './Footer';
import Navhead from './Navhead';
import { useNavigate } from 'react-router-dom';

export default function Mysubscription(props) {
    let cartItems = props.cartItems;
    const email = JSON.parse(localStorage.getItem('emaillocal'));
    const names = JSON.parse(localStorage.getItem('user-name'));
    const navigatelog = useNavigate();
    const [userdet, setUserdet] = useState([]);
    /*     useEffect(async () => {
            let result = await fetch("https://foodyinn.in/api/fetchlogdetails.php?email=" + email);
            result = await result.json();
            setUserdet(result);
            console.warn("data", userdet);
    
        }, []) */
    return (
        <div>
            <Navhead cartItemnav={cartItems.length} />
            <div class="container product-page" id="top">
                <div class="row">
                    <div class="col-sm-12" align={'left'}>
                        <Link to="/" style={{ color: '#000000', fontSize: '12px' }}>Home &gt; </Link>
                        <Link to="/mysubscription" style={{ color: '#000000', fontSize: '12px' }}>My Subscription  </Link>
                    </div>
                </div>
            </div>
            <div class="container-fluid product">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-3" id="mobile">
                            <Dashsidelist />
                        </div>
                        <div class="col-sm-9">
                            <div class="row">
                                <div class="col-sm-12" >
                                    <p align="left">
                                        Hello, {names} <br />
                                        {email ? "" : navigatelog("/account")}
                                        Currently you are Subscribed to our Free Plan.
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class=" d-flex justify-content-center my-3">
                                        <div class="card" style={{ width: '300px', height: "350px" }}>
                                            <div class="card-header text-center "><h5>Starsplex</h5></div>
                                            <div class="card-body ">
                                                <h6 class="text-center">FREE PLAN</h6>
                                                <table class="table">
                                                    <tbody>
                                                        <tr class="text-left">
                                                            <td><b>Status</b></td>
                                                            <td>Active</td>
                                                        </tr>
                                                        <tr class="text-left">
                                                            <td><b>Region</b></td>
                                                            <td>World Wide</td>
                                                        </tr>
                                                        <tr class="text-left">
                                                            <td><b>Auto Renewal</b></td>
                                                            <td>No</td>
                                                        </tr>
                                                        <tr class="text-left">
                                                            <td><b>Expires On</b></td>
                                                            <td>31 Dec 2024</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3" id="mobileshow">
                            <Dashsidelist />
                        </div>
                    </div>
                    <div class="col-sm-4">
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
import React,{useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Navhead from './Navhead';
import {NavLink, useParams} from 'react-router-dom';

function Simredirect(props)
{
    let cartItems=props.cartItems;
    let prodid = useParams();
    let productid = prodid.id;    



    const navigate=useNavigate();


    useEffect(()=>{
        navigate('/proddetails/'+productid);        
    },[])



    return(
        <div>
            <Navhead cartItemnav={cartItems.length} />

        </div>
    );
}
export default Simredirect;
import React, { useState, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import { Membersdashsidelist } from './memberdashsidelist';
import { Filterside } from './Filterside';
import Footer from './Footer';
import Navhead from './Navhead';
import { useNavigate } from 'react-router-dom';

export default function Membersdashboard(props) {

  let cartItems = props.cartItems;

  const email = JSON.parse(localStorage.getItem('emaillocal'));
  /*   const names = JSON.parse(localStorage.getItem('user-name')); */
  const navigatelog = useNavigate();

  const [userdet, setUserdet] = useState([]);

  useEffect(async () => {

    fetch("https://www.starsplex.com/api/memberdetailsfetch.php", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(email)


    }).then((result) => {
      result.json().then((resp) => {

        setUserdet(resp[0])

      })
    })

    /*       let result=await fetch("https://www.starsplex.com/api/memberdetailsfetch.php");
          result = await result.json();
          setUserdet(result);
          console.warn("data",userdet); */

  }, [])




  return (
    <div>
      <Navhead cartItemnav={cartItems.length} />


      <div class="container product-page" id="top">
        <div class="row">
          <div class="col-sm-12" align={'left'}>
            <Link to="/" style={{ color: '#000000', fontSize: '12px' }}>Home &gt; </Link>
            <Link to="#" style={{ color: '#000000', fontSize: '12px' }}>My Account  </Link>

          </div>
        </div>

      </div>


      <div class="container-fluid product">
        <div class="container">
          <div class="row">

            <div class="col-sm-3" id="mobile">
              <Membersdashsidelist />
            </div>
            <div class="col-sm-9">
              <div class="row">
                <div class="col-sm-12" >
                  <p align="left">

                    Hello, {userdet.fname} <br />
                    {email ? "" : navigatelog("/account")}

                    From your account dashboard you can view your recent orders and manage account details.<br />
                    Please send your content details to <b>starsplexofficial@gmail.com</b>, mentioning that you want to promote your content at Starsplex portal.
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">

                  <div class=" d-flex justify-content-center my-3">
                    <a href="#demo" class="btn btn-outline-dark" data-toggle="collapse" data-target="#demo" style={{ color: "white", borderColor: "white" }}>Account Details
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-down-circle-fill mx-2" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                      </svg>
                    </a>
                  </div>
                  <div id="demo" class="collapse mt-4">
                    <table class="table table-bordered" style={{color:"white"}}>
                      <tbody>
                        <tr>
                          <td><b>Company Name</b></td>
                          <td>{userdet.fname}</td>
                        </tr>
                        <tr>
                          <td><b>Owner Name</b></td>
                          <td>{userdet.lname}</td>
                        </tr>
                        <tr>
                          <td><b>Email</b></td>
                          <td>{userdet.email}</td>
                        </tr>
                        <tr>
                          <td><b>Mobile</b></td>
                          <td>{userdet.mobile}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>


            </div>
            <div class="col-sm-3" id="mobileshow">
              <Membersdashsidelist />
            </div>

          </div>
          <div class="col-sm-4">

          </div>




        </div>
      </div>


      <Footer />
    </div>
  )
}
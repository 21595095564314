import React,{useState,useEffect} from 'react';
import Navhead from './Navhead';
import { useParams } from "react-router-dom";
import {NavLink, Link} from "react-router-dom";
import {useNavigate} from 'react-router-dom';
import { Dashsidelist } from './Dashsidelist';
import 'react-owl-carousel2/lib/styles.css';
import 'react-owl-carousel2/src/owl.theme.default.css';

import './Proddetails.css';
import Footer from './Footer';

function Newdeladd(props)
{
    console.warn("propsdet",props);
    let cartItems=props.cartItems;
    let prodid = useParams();
    let itemid = prodid.id;
    const [mobile,setMobile]=useState([]);
    const [address,setAddress]=useState([]);
    const [pin,setPin]=useState([]);
    const [city,setCity]=useState([]);
    const { product, onAdd } = props;
    const [state,setState]=useState([]);
    const navigatedel=useNavigate();
    const [revbtn,setRevbtn]=useState("Add New Address!");

    const emailcheck=JSON.parse(localStorage.getItem('emaillocal'));
    const names=JSON.parse(localStorage.getItem('user-name'));


    function saveAddress(){
        console.warn("del:",names,pin,address,mobile,city,state,emailcheck);
    
        let data={names,pin,address,mobile,city,state,emailcheck};
    
        fetch("https://www.foodyinn.in/api/savedeladd.php",{
            method:'POST',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json'
            },
            body:JSON.stringify(data)
    
            
        }).then((result)=>{
            // console.warn("result",result);
            result.json().then((resp)=>{
                console.warn("resp",resp);               
                setRevbtn(resp);
                
            })
        })
    }





    return(
        <div>
        <Navhead cartItemnav={cartItems.length} />
        


<div class="container">

<div id="ratingDetails" style={{textAlign:'left'}}> 		<br/><br/>
		<div class="row">			
        <div class="col-sm-3" id="mobile">
            <Dashsidelist />
        </div>

			<div class="col-sm-9">			
            <p align="left">    

                Hello,{names} <br/>
                {emailcheck? ""   :navigatedel("/account")}
                
                Please click on <i><b>Add New Address</b></i> button to add new delivery address.
            </p>            	
                <button data-toggle="collapse" data-target="#ratingSection" class="btn btn-outline-primary" style={{textAlign:'center'}}>+ Add New Address</button>            

                {emailcheck? 

    <div id="ratingSection" class="collapse">
		<div class="row">
			<div class="col-sm-12">

					<div class="form-group">
						<h4>Add Address</h4>

					</div>		
					<div class="form-group">
						<label for="usr">PIN CODE*</label>
						<input type="text" class="form-control" value={pin} onChange={(e)=>{setPin(e.target.value)}} id="title" name="title" required />
					</div>
					<div class="form-group">
						<label for="comment">Address*</label>
						<textarea class="form-control" rows="5" id="comment" value={address} onChange={(e)=>{setAddress(e.target.value)}} name="comment" required></textarea>
					</div>
					<div class="form-group">
						<label for="usr">Mobile*</label>
						<input type="text" class="form-control" value={mobile} onChange={(e)=>{setMobile(e.target.value)}} id="title" name="title" required />
					</div>

					<div class="form-group">
						<label for="usr">City*</label>
						<input type="text" class="form-control" value={city} onChange={(e)=>{setCity(e.target.value)}} id="title" name="title" required />
					</div>
					<div class="form-group">
						<label for="usr">State*</label>
						<input type="text" class="form-control" value={state} onChange={(e)=>{setState(e.target.value)}} id="title" name="title" required />
					</div>

					<div class="form-group">
						<button type="submit" class="btn btn-info" onClick={saveAddress} name="savr" id="saveReview">{revbtn}</button> <button type="button" class="btn btn-info" id="cancelReview">Cancel</button>
					</div>			

			</div>
		</div>		
	</div>
	   :""}

            </div>            
        </div>                    	




								

</div>


<br/>




</div>




<Footer />
        </div>
    );
}
export default Newdeladd;
import React, { useState, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import { Filterside } from './Filterside';
import Footer from './Footer';
import { useParams } from "react-router-dom";
import Navhead from './Navhead';

export default function Manu() {

  const [foodcat, setFoodcat] = useState([]);
  let catid = useParams();

  useEffect(async () => {
    let result = await fetch('https://foodyinn.in/api/singlecategory.php?prod=' + catid.id);
    result = await result.json();
    setFoodcat(result);
    console.warn("data", foodcat);
  }, [])


  return (
    <div>
      <Navhead />


      <div class="container product-page" id="top">
        <div class="row">
          <div class="col-sm-12" align={'left'}>
            <Link to="/" style={{ color: '#000000', fontSize: '12px' }}>Home &gt; </Link>
            <Link to="#" style={{ color: '#000000', fontSize: '12px' }}>Veg  </Link>

          </div>
        </div>

      </div>


      <div class="container-fluid product">
        <div class="container">
          <div class="row">

            <div class="col-sm-3" id="sidebar">
              <Filterside />
            </div>
            <div class="col-sm-9">
              <div class="row">
                <div class="col-sm-12" >
                  <p>
                    <h3 class="justify-content-start">Tandoor</h3>
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">

                  {
                    foodcat.map((item) =>
                      <NavLink to={"/prodetails?item=" + item.id + "&title=" + item.name} style={{ textDecoration: 'none' }}>

                        <div style={{ width: '205px', marginLeft: '10px', float: 'left' }}>
                          <img src={"https://foodyinn.in/products/" + item.thumbnail} style={{ height: "150px", width: "200px", marginRight: '5px', paddingRight: '0px', borderRadius: "10px" }} alt={item.thumbnail} />
                          <div style={{ backgroundColor: 'black', opacity: '.7', marginTop: '-25px', marginRight: '5px', paddingRight: '5px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', color: '#FFF' }} >{item.name}</div>
                        </div>
                      </NavLink>

                    )
                  }


                </div>
              </div>







            </div>
          </div>
          <div class="col-sm-4">

          </div>




        </div>
      </div>


      <Footer />
    </div>
  )
}
import React, { useEffect, useState } from 'react';
import Navhead from './Navhead';
import { Link, NavLink } from 'react-router-dom';
import './Home.css';
import { Helmet } from 'react-helmet';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import Footer from './Footer';
import './global.css';

function Musics(props) {
    let cartItems = props.cartItems;
    const [data, setData] = useState([]);
    const [datavideos, setDatavideos] = useState([]);
    const [datafilms, setDatafilms] = useState([]);
    const [datamusic, setDatamusic] = useState([]);
    const [veg, setVeg] = useState([]);

    useEffect(() => {

        fetch("https://www.starsplex.com/api/latestshows.php").then((result) => {
            result.json().then((resp) => {

                setData(resp);
                console.warn("RESP", resp);
            })
        });

        fetch("https://www.starsplex.com/api/fetchvideos.php").then((resultvideos) => {
            resultvideos.json().then((respvideos) => {
                setDatavideos(respvideos);
            })
        });
        fetch("https://www.starsplex.com/api/fetchmovies.php").then((resultfilms) => {
            resultfilms.json().then((respfilms) => {

                setDatafilms(respfilms);

            })
        });
        fetch("https://www.starsplex.com/api/fetchmusics.php").then((resultmusic) => {
            resultmusic.json().then((respmusic) => {

                setDatamusic(respmusic);
            })
        });

        /*         fetch("https://foodyinn.in/api/fetchproductsveg.php").then((resultveg) => {
                    resultveg.json().then((respveg) => {
        
                        setVeg(respveg);
                        console.warn("RESPveg", respveg);
                    })
                });
         */

    }, [])

    const options = {
        stagePadding: 39,
        nav: false,
        dots: false,
        margin: 10,
        loop: true,
        autoplay: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    };

    const optionswn = {
        loop: false,
        nav: false,

        dots: false,
        margin: 60,
        arrow: false,
        responsiveClass: true,
        responsive: {
            0: {
                items: 2
            },
            600: {
                items: 3
            },
            1000: {
                items: 5
            }
        }
    };




    return (
        <div>
            <Navhead cartItemnav={cartItems.length} />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Musics Starsplex - Watch Movies,Videos Musics Online</title>
                <meta property="title" content="Musics Starsplex - Watch Movies,Videos Musics Online" />
                <meta property="description" content="Musics Starsplex - Watch Movies,Videos Musics Online" />
            </Helmet>
            <OwlCarousel options={options} style={{ marginTop: '1px' }} >
                <div><img id="slidemain" src={"https://starsplex.com/uploads/img/m2.jpg"} alt="No" style={{ borderRadius: '15px' }} /></div>
                <div><img id="slidemain" src={"https://starsplex.com/uploads/img/darjararale.png"} alt="No" style={{ borderRadius: '15px' }} /></div>
                <div><img id="slidemain" src={"https://starsplex.com/uploads/img/kapurush.jpg"} alt="No" style={{ borderRadius: '15px' }} /></div>
            </OwlCarousel>




            <div class="container">
                <br />
                <br />
                <br />
                <div class="row">
                    <div class="col-sm-12">
                        <h5 align={"left"}><i>Music</i></h5>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <div class="list-group desktopview">
                            {
                                datamusic.map((item) =>
                                    <NavLink to={"/watch?title=" + item.id + "&n=" + item.videoname} style={{ textDecoration: 'none', borderRadius: '5px', marginBottom: '2px' }}>
                                        <span class="list-group-item list-group-item-action text-left">
                                            <img src={"https://www.starsplex.com/uploads/img/" + item.name} style={{ height: "50px", width: "50px", marginRight: '15px', paddingRight: '0px', borderRadius: "10px" }} alt={item.name} />
                                            {item.videoname}
                                        </span>
                                    </NavLink>
                                )
                            }
                        </div>
                        <div class="list-group mobileview px-0">
                            {
                                datamusic.map((item) =>
                                    <NavLink to={"/watch?title=" + item.id + "&n=" + item.videoname} style={{ textDecoration: 'none', borderRadius: '5px', marginBottom: '2px' }}>
                                        <span class="list-group-item list-group-item-action text-left px-1">
                                            <img src={"https://www.starsplex.com/uploads/img/" + item.name} style={{ height: "50px", width: "50px", marginRight: '15px', paddingRight: '0px', borderRadius: "10px" }} alt={item.name} />
                                            {item.videoname.substring(0, 30) + "..."}
                                        </span>
                                    </NavLink>
                                )
                            }
                        </div>
                    </div>
                </div>


                <br />



            </div>

            <Footer />
        </div>
    );
}
export default Musics;
import React, { useState, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import { Dashsidelist } from './Dashsidelist';
import { Filterside } from './Filterside';
import Footer from './Footer';
import Navhead from './Navhead';
import { useNavigate } from 'react-router-dom';

export default function Accountdetails(props) {

  let cartItems = props.cartItems;

  const email = JSON.parse(localStorage.getItem('emaillocal'));
  const names = JSON.parse(localStorage.getItem('user-name'));
  const navigatelog = useNavigate();

  const [userdet, setUserdet] = useState([]);

  useEffect(async () => {

  await fetch("https://www.starsplex.com/api/fetchuserdetails.php", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(email)


    }).then((result) => {
      result.json().then((resp) => {

        setUserdet(resp)

      })
    })

  }, [])




  return (
    <div>
      <Navhead cartItemnav={cartItems.length} />


      <div class="container product-page" id="top">
        <div class="row">
          <div class="col-sm-12" align={'left'}>
            <Link to="/" style={{ color: '#000000', fontSize: '12px' }}>Home &gt; </Link>
            <Link to="#" style={{ color: '#000000', fontSize: '12px' }}>My Account  </Link>

          </div>
        </div>

      </div>


      <div class="container-fluid product">
        <div class="container">
          <div class="row">

            <div class="col-sm-3" id="mobile">
              <Dashsidelist />
            </div>
            <div class="col-sm-9">
              <div class="row">
                <div class="col-sm-12" >
                  <p align="left">

                    Hello, {names} <br />
                    {email ? "" : navigatelog("/account")}

                    Account Details
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">

                  {
                    userdet.map((item) =>


                      <div >
                        <table class="table table-bordered">
                          <tbody align="left">
                            <tr>
                              <td><b>Full Name</b></td>
                              <td>{item.fname}&nbsp;{item.lname}</td>
                            </tr>
                            <tr>
                              <td><b>Email</b></td>
                              <td>{item.email}</td>
                            </tr>
                            <tr>
                              <td><b>Mobile</b></td>
                              <td>{item.mobile}</td>
                            </tr>
  {/*                           <tr>
                              <td><b>Address</b></td>
                              <td>{item.address}</td>
                            </tr>
                            <tr>
                              <td><b>Pincode</b></td>
                              <td>{item.pincode}</td>
                            </tr> */}
                          </tbody>
                        </table>


                      </div>

                    )
                  }


                </div>
              </div>







            </div>
            <div class="col-sm-3" id="mobileshow">
              <Dashsidelist />
            </div>

          </div>
          <div class="col-sm-4">

          </div>




        </div>
      </div>


      <Footer />
    </div>
  )
}
import React from 'react';
import Navhead from './Navhead';
import { Link } from 'react-router-dom';

function Nopage()
{
    return(
        <div>
            <Navhead />
            <h1 style={{fontSize:"100px"}} class="mt-3">404</h1>
            <h5 style={{fontSize:"20px", textAlign:'center'}}>Sorry,The resource requested could not be found on this server!</h5>
            <Link to="/"><button type="button" class="btn btn-outline-secondary">Go Back To Home!</button></Link>
        </div>
    );
}
export default Nopage;
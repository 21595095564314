import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navhead from './Navhead';
import { Helmet } from 'react-helmet';
import Footer from './Footer';

function Privacypolicy(props) {
    let cartItems = props.cartItems;

    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [emailreg, setEmailreg] = useState("");
    const [passwordreg, setPasswordreg] = useState("");
    const [mobile, setMobile] = useState("");
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [logstatus, setLogstatus] = useState("");
    const [Signupstatus, setSignupstatus] = useState("");
    const [Loginstatus, setLoginstatus] = useState("");
    const [sent, setSent] = useState(false);

    function saveUser() {
        console.warn(fname, lname, emailreg, passwordreg, mobile);

        let data = { fname, lname, emailreg, passwordreg, mobile };

        if (fname === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (lname === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (emailreg === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (passwordreg === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (mobile === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else {


            fetch("https://www.starsplex.com/api/contactus.php", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)


            }).then((result) => {
                // console.warn("result",result);
                result.json().then((resp) => {
                    console.warn("resp", resp);
                    // navigate("/navhead");
                    setSent(true);

                })
            })

        }
    }


    return (
        <div>
            <Navhead cartItemnav={cartItems.length} />
            <br />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Privacy Policy | Starsplex - Watch Movies,Videos Musics Online</title>
                <meta property="title" content="Privacy Policy | Starsplex - Watch Movies,Videos Musics Online" />
                <meta property="description" content="Privacy Policy | Starsplex - Watch Movies,Videos Musics Online" />
            </Helmet>
            <div class="container">
                <h2>Starsplex Privacy Policy</h2>
                <h5>Please read all the <b>Privacy Policy</b> carefully.</h5>
                <br />

                <div id="accordion">
                    <div class="card">
                        <div class="card-header text-left">
                            <a class="card-link" data-toggle="collapse" href="#collapseOne">
                                What Kind of Information Do We Collect?
                            </a>
                        </div>
                        <div id="collapseOne" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                                At Starsplex We collect only those information from you at the time of registration on our site and also for place an order like subscription or tickets booking, which is essential.
                                Any data we request that is not required will be specified as optional.
                                When placeing an order or registering on our site, as appropriate, you may be asked to enter your: name, e-mail address, mailing address or phone number.However, you may also visit our site without registration.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header text-left">
                            <a class="collapsed card-link" data-toggle="collapse" href="#collapseTwo">
                                What we do with your information?
                            </a>
                        </div>
                        <div id="collapseTwo" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                                At Starsplex any of the information we collect from you may be used in one of the following ways:
                                To personalize your experience. (your information helps us to better respond to your individual needs) To improve our website. (we continually strive to improve our website offerings based on the information and feedback we receive from you) To improve customer service. (your information helps us to more effectively respond to your customer service requests and support needs) To send periodic emails. (The email address you provide for order processing, may be used to send you information and updates pertaining to your order or request.)
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header text-left">
                            <a class="collapsed card-link" data-toggle="collapse" href="#collapseThree">
                                How do we protect your information?
                            </a>
                        </div>
                        <div id="collapseThree" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                                We implement a variety of security measures to maintain the safety of your personal information when you submit a request, place an order or access your personal information. These security measures include: password protected directories and databases to safeguard your information, SSL (Secure Sockets Layered) technology to ensure that your information is fully encrypted and sent across the Internet securely or PCI Scanning to actively protect our servers from hackers and other vulnerabilities. After a transaction, your private information (credit cards, social security numbers, financials, etc.) will not be stored on our servers.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header text-left">
                            <a class="collapsed card-link" data-toggle="collapse" href="#collapsefour">
                                Do we disclose any information to outside parties?
                            </a>
                        </div>
                        <div id="collapsefour" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                                We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header text-left">
                            <a class="collapsed card-link" data-toggle="collapse" href="#collapsefive">
                                Third party links
                            </a>
                        </div>
                        <div id="collapsefive" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                                Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header text-left">
                            <a class="collapsed card-link" data-toggle="collapse" href="#collapsesix">
                                Terms and Conditions
                            </a>
                        </div>
                        <div id="collapsesix" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                                Please also visit our Terms and Conditions section establishing the use, disclaimers, and limitations of liability governing the use of our website at www.starsplex.com
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header text-left">
                            <a class="collapsed card-link" data-toggle="collapse" href="#collapsesix">
                                Changes to our Privacy Policy
                            </a>
                        </div>
                        <div id="collapsesix" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                                If we decide to change our privacy policy, we will post those changes on this page.
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Footer />

        </div>
    );
}
export default Privacypolicy;
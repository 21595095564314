import React from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

export function Membersdashsidelist() {
    const navigate = useNavigate();
    function Logout(){
        localStorage.clear();
        localStorage.removeItem("user-name");
        localStorage.removeItem("emaillocal");
        navigate("/members");
    }


    return (
        <div>
            <ul class="list-group">
                <li class="list-group-item  bg-dark text-white">My Account</li>
                <Link class="list-group-item list-group-item-action" to="/membersdashboard">Dashboard</Link>
                <Link class="list-group-item list-group-item-action" to="/membersdashboard">Account Details</Link>
{/*                 <Link class="list-group-item list-group-item-action" to="/orders">Orders</Link>
                <Link class="list-group-item list-group-item-action" to="/wallet">Wallet</Link> */}
                {/*     <Link class="list-group-item list-group-item-action" to="/deliveryaddress">Delivery Address</Link>      */}
                <span class="list-group-item list-group-item-action" onClick={()=> Logout()}>Logout</span>
            </ul>
        </div>
    )
}

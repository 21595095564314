import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navhead from './Navhead';
import { Helmet } from 'react-helmet';
import Footer from './Footer';

function Refundpolicy(props) {
    let cartItems = props.cartItems;

    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [emailreg, setEmailreg] = useState("");
    const [passwordreg, setPasswordreg] = useState("");
    const [mobile, setMobile] = useState("");
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [logstatus, setLogstatus] = useState("");
    const [Signupstatus, setSignupstatus] = useState("");
    const [Loginstatus, setLoginstatus] = useState("");
    const [sent, setSent] = useState(false);

    function saveUser() {
        console.warn(fname, lname, emailreg, passwordreg, mobile);

        let data = { fname, lname, emailreg, passwordreg, mobile };

        if (fname === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (lname === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (emailreg === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (passwordreg === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (mobile === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else {


            fetch("https://www.starsplex.com/api/contactus.php", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)


            }).then((result) => {
                // console.warn("result",result);
                result.json().then((resp) => {
                    console.warn("resp", resp);
                    // navigate("/navhead");
                    setSent(true);

                })
            })

        }
    }


    return (
        <div>
            <Navhead cartItemnav={cartItems.length} />
            <br />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Refund Policy | Starsplex - Watch Movies,Videos Musics Online</title>
                <meta property="title" content="Refund Policy | Starsplex - Watch Movies,Videos Musics Online" />
                <meta property="description" content="Refund Policy | Starsplex - Watch Movies,Videos Musics Online" />
            </Helmet>
            <div class="container">
                <h2>Starsplex Refund Policy</h2>
                <h5>Please read all the <b>Refund Policy</b> carefully.</h5>
                <br />
                <div class="text-left">
                    <h4><b>Refund Policy</b></h4>
                    This Policy applies to Starsplex website platforms including without limitation www.starsplex.com (https://www.starsplex.com) and other related Site/s or App/s, mobile applications and other online features each a “Site/s or App/s”. We have provided extensive information for you to view the packages before choosing to subscribe to us. If you have any questions or reservations, please contact us at support@starsplex.com prior to subscribing to our services.

                    We, being the service providers for contents available through website, where you are expected to view packages of your choice after being paid for subscription. Fees for such services are refundable only in case of any kind of technical glitch by 7 working days.

                    At the time of online transaction, if the transaction does not occur, the amount in process of transfer by default goes back into your bank account, automatically through Payment Gateway.

                    <br /><br />
                    <h4><b>Chargebacks</b></h4>
                    If we receive a chargeback or payment dispute from a credit card company or bank, your service and/or subscription will be suspended without notice. Applicable chargeback fee will be issued to recover fees passed on to us by the credit company, plus any outstanding balances accrued as a result of the chargeback(s) must be paid in full before service is restored. Instead of issuing a chargeback, contact us to address any billing issues. Requesting a chargeback or opening any sort of dispute for a valid charge from us is fraud, and is never an appropriate or legal means of obtaining a refund.

                    PLEASE READ AND MAKE SURE YOU FULLY UNDERSTAND OUR REFUND POLICY PRIOR TO MAKING A PAYMENT.
                    <br /><br />
                    <h4><b>Contact Us</b></h4>

                    You can contact us at our email id support@starsplex.com.
                </div>
            </div>

            <Footer />

        </div>
    );
}
export default Refundpolicy;